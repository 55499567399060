<template>
  <v-container class="new-detail">
    <v-row class="row">
      <v-col cols="12" class="h4">
        {{ detail.title }}
      </v-col>
    </v-row>
    <v-row class="row">
      <v-col cols="2" class="font-weight-medium">
        {{language.slug}}
      </v-col>
      <v-col cols="10" class="font-weight-regular">
        {{ detail.slug }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="font-weight-medium">
        {{language.content}}
      </v-col>
      <v-col cols="10">
        <v-img
          lazy-src="https://picsum.photos/id/11/10/6"
          max-height="150"
          max-width="250"
          :src="detail.image_url"
        ></v-img>
        <div v-html="detail.content"></div>
      </v-col>
    </v-row>
    <v-row class="row">
      <v-col cols="2" class="font-weight-medium">
        {{language.seoTitle}}
      </v-col>
      <v-col cols="10">
        {{ detail.seo_title }}
      </v-col>
    </v-row>
    <v-row class="row">
      <v-col cols="2" class="font-weight-medium">
        {{language.seoDescription}}
      </v-col>
      <v-col cols="10" class="col-10">
        {{ detail.seo_description }}
      </v-col>
    </v-row>
    <v-row class="row">
      <v-col cols="2" class="font-weight-medium">
        {{language.seoKey}}
      </v-col>
      <v-col cols="10">
        {{ detail.seo_key }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_DETAIL_NEWS } from "@/store/new.module";

export default {
  //detail page
  name: "detail",
  created() {
    let payload = {
      news_id: this.$route.query.news_id,
    };
    this.$store.dispatch(GET_DETAIL_NEWS, payload);
  },
  data:()=>{
    return{
      language: {
        slug: "slug",
        content: "Nội dung bài viết",
        image: "Ảnh tiêu đề",
        seoTitle: "SEO Title",
        seoDescription: "SEO Description",
        seoKey: "SEO Keyword"
      },
    }
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      detail: "getDetailNew"
    })
  }
};
</script>

<style>
.new-detail .row .col-10 p {
  margin-top: 30px;
}
.new-detail .row .col-10 .v-image {
  margin-top: 30px;
}
</style>
